import React from "react";
import Layout from "../components/Layout/Layout";
import WaverChickenGrid from '../components/Grid/WaverChickenGrid';
import './about.scss';
import Founder from '../img/Founder.jpg';

const OurStory = (props) => {
  return (
    <Layout
      metaTitle="Frozen Breaded Chicken for Your Family"
      metaDescription=""
      searchTitle="About"
    >
      <WaverChickenGrid>
        <div className="aboutPage">
          <p><b>About</b></p>

          <p>The <b>Weaver</b>® brand of products is known today for its family-focused line of fully-cooked chicken products.</p>
          
          <div className="founder">
            <p>The tradition of great tasting Weaver chicken was started more than 70 years ago by Victor F. Weaver. Victor was a true American entrepreneur. Early in 1937, he and his father opened a roadside stand in a new Sharon Hill market near Philadelphia selling broilers that were raised on his family farm. As his business grew, Victor began buying from independent poultry farms.</p>

            <img 
            className="" 
            src={Founder}
            alt={"Founder Victor Weaver"}
            />
          </div>
          
          <p>A year after opening the stand, Victor and his wife Edith purchased four acres of land in New Holland, Pennsylvania, which included a house, a shed, a chicken house, and other facilities. This land is the site of a processing plant that still makes Weaver products today.</p>
          <p>Over the years, the Weaver brand developed a reputation for high quality chicken. The business grew rapidly in the 1940s and 1950s, and the company began providing further processed chicken. In 1960, experimentation on a breaded chicken steak led to the predecessor of today's Weaver Original Chicken Patty.</p>
          <p>The Weaver line expanded to include frozen breaded boneless chicken, croquettes, wings, and other prepared items. Premium quality products and attractive packaging drove sales in the sixties and seventies. Soon Weaver became one of the hottest moving prepared poultry product lines in the Northeast.</p>
          <p>As a part of Tyson Foods, Weaver chicken continues the tradition of Victor Weaver, bringing delicious, wholesome products to your family's dinner table.</p>
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default OurStory;